
export default function Vehicles(){
    return (
        <div className="mt-30">
             <h4
        className="heading page-container"
  
  >We have everything you need</h4>

<div className="mt-10 p-10">
            <div
            className="d-flex flex-wrap space-around gap-30 space-evenly"
            >

            <div
            className="what-we-do-container"
        
            >
                <div className="text-center">

                    <div className="center">
                    <div className="what-we-do-img-container border-black">
                       <img  className="what-we-do-icon" src={require("../../images/fuso_lori.png")} />
                    </div>
                    </div>
                    <div
                    className="what-we-offer-headings"
                    >
                    <h4 className="mt-30">TOWNACE</h4>
                </div>
                </div>

                
            </div>


            <div
            className="what-we-do-container"
        
            >
                <div className="text-center">

                    <div className="center">
                    <div className="what-we-do-img-container border-black">
                       <img  className="what-we-do-icon" src={require("../../images/car_van.png")} />
                    </div>
                    </div>
                    <div
                    className="what-we-offer-headings"
                    >
                    <h4 className="mt-30">CAR / VAN</h4>
                </div>
                </div>

                
            </div>



            <div
            className="what-we-do-container"
        
            >
                <div className="text-center">

                    <div className="center">
                    <div className="what-we-do-img-container border-black">
                       <img  className="what-we-do-icon" src={require("../../images/bajaji.png")} />
                    </div>
                    </div>
                    <div
                    className="what-we-offer-headings"
                    >
                    <h4 className="mt-30">BAJAJI</h4>
                </div>
                </div>

                
            </div>



            <div
            className="what-we-do-container"
        
            >
                <div className="text-center">

                    <div className="center">
                    <div className="what-we-do-img-container border-black">
                       <img  className="what-we-do-icon" src={require("../../images/boda_boda.png")} />
                    </div>
                    </div>
                    <div
                    className="what-we-offer-headings"
                    >
                    <h4 className="mt-30">BODA BODA</h4>
                </div>
                </div>

                
            </div>


            </div>
            </div>


  </div>
    );
}