
export default function WhatWeOffer(){

    return(
        <div className="mt-30">
             <h4
        className="heading page-container"
  
  >What We Offer</h4>

        <div className="mt-10 bg-primary p-10">
            <div
            className="row space-evenly"
            >
            <div
            className="flex-1 what-we-do-container"
        
            >
                <div className="text-center">

                    <div className="center">
                    <div className="what-we-do-img-container">
                        <CircleTickIcon />
                    </div>
                    </div>
                    <div
                    className="what-we-offer-headings"
                    >
                    <h4 className="text-white mt-30">Hassle free truck rental</h4>

<h6 className="text-white mt-10 mb-10 mx-60">Book mini truck online. Whenever you need, wherever you need</h6>
                </div>
                </div>

                
            </div>


            <div
            className="flex-1 what-we-do-container"
        
            >
                <div className="text-center">

                    <div className="center">
                    <div className="what-we-do-img-container">
                        <PricingIcon />
                    </div>
                    </div>
                    <div
                    className="what-we-offer-headings"
                    >
                    <h4 className="text-white mt-30">Transparent Pricing</h4>

<h6 className="text-white mt-10 mb-10 mx-60">Enjoy the most affordable rates in town with our transparent pricing</h6>
                </div>
                </div>

                
            </div>

            <div
            className="flex-1 what-we-do-container"
        
            >
                <div className="text-center">

                    <div className="center">
                    <div className="what-we-do-img-container">
                        <LocationIcon />
                    </div>
                    </div>
                    <div
                    className="what-we-offer-headings"
                    >
                    <h4 className="text-white mt-30">Realtime Tracking</h4>

<h6 className="text-white mt-10 mb-10 mx-60">Track your goods movement across the city with our app</h6>

            </div>
                </div>

                
            </div>

            <div
            className="flex-1 what-we-do-container"
        
            >
                <div className="text-center">

                    <div className="center">
                    <div className="what-we-do-img-container">
                        <TruckIcon />
                    </div>
                    </div>
                    <div
                    className="what-we-offer-headings"
                    >
                          <h4 className="text-white mt-30">Safe and Reliable Trucks</h4>

<h6 className="text-white mt-10 mb-10 mx-60">Superior safety ensured with our team of verified & trained partners</h6>


                    </div>
                  
                </div>

                
            </div>


         

         


            </div>

        </div>
  
        </div>
  );

} 


function CircleTickIcon(){
    return (
        <svg 
        className="what-we-do-icon"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"/></svg>
    );
}

function PricingIcon(){
    return (
        <svg 
        className="what-we-do-icon"
        
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4zM.0003 229.5V80C.0003 53.49 21.49 32 48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5L.0003 229.5zM112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112z"/></svg>
    );
}

function LocationIcon(){
    return(
        <svg 
        className="what-we-do-icon"
        
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M273.2 311.1C241.1 271.9 167.1 174.6 167.1 120C167.1 53.73 221.7 0 287.1 0C354.3 0 408 53.73 408 120C408 174.6 334.9 271.9 302.8 311.1C295.1 321.6 280.9 321.6 273.2 311.1V311.1zM416 503V200.4C419.5 193.5 422.7 186.7 425.6 179.8C426.1 178.6 426.6 177.4 427.1 176.1L543.1 129.7C558.9 123.4 576 135 576 152V422.8C576 432.6 570 441.4 560.9 445.1L416 503zM15.09 187.3L137.6 138.3C140 152.5 144.9 166.6 150.4 179.8C153.3 186.7 156.5 193.5 160 200.4V451.8L32.91 502.7C17.15 508.1 0 497.4 0 480.4V209.6C0 199.8 5.975 190.1 15.09 187.3H15.09zM384 504.3L191.1 449.4V255C212.5 286.3 234.3 314.6 248.2 331.1C268.7 357.6 307.3 357.6 327.8 331.1C341.7 314.6 363.5 286.3 384 255L384 504.3z"/></svg>
    );
}

function TruckIcon(){
    return (
        <svg 
        className="what-we-do-icon"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M368 0C394.5 0 416 21.49 416 48V96H466.7C483.7 96 499.1 102.7 512 114.7L589.3 192C601.3 204 608 220.3 608 237.3V352C625.7 352 640 366.3 640 384C640 401.7 625.7 416 608 416H576C576 469 533 512 480 512C426.1 512 384 469 384 416H256C256 469 213 512 160 512C106.1 512 64 469 64 416H48C21.49 416 0 394.5 0 368V48C0 21.49 21.49 0 48 0H368zM416 160V256H544V237.3L466.7 160H416zM160 368C133.5 368 112 389.5 112 416C112 442.5 133.5 464 160 464C186.5 464 208 442.5 208 416C208 389.5 186.5 368 160 368zM480 464C506.5 464 528 442.5 528 416C528 389.5 506.5 368 480 368C453.5 368 432 389.5 432 416C432 442.5 453.5 464 480 464z"/></svg>
    );
}