import "./NavBar.css";
import Logo from "../../logo.svg";
import AboutUs from "../../about_us.pdf";
import { useNavigate } from "react-router-dom";

export default function NavBar(){
    const navigate = useNavigate();
    return(
        <nav className="navbar">
            <button
            onClick={() => navigate("/")}
            className="navbar-brand"
            >
                <img 
                    src={Logo}
                    alt="Mizigo logo"
                    className="navbar-logo"
                />
                <p className="navbar-title">
                Tanzania's Largest Marketplace for Intracity Logistics
                </p>
            </button>
        
            <div className="navbar-end">

            <button
           className="navbar-button"
           >
               <i className="fa fa-car navbar-button-icon"></i>
               Drive with us
             

           </button>
           <a
           className="navbar-button-no-bg"
           href="tel:0800750235"
           >
               <i className="fa fa-phone navbar-button-no-bg-icon"></i>
               080 0750 235
             

           </a>

           <a
           className="navbar-button-no-bg"
           href={AboutUs}
           download="about_us.pdf"
           >
               <i className="fa fa-download navbar-button-no-bg-icon"></i>
                Know More
             

           </a>
            </div>
          
        

        </nav>
    );

}