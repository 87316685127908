
export default function TrackRecord(){

    return(
        <div className="p-10 bg-primary">
            <h4 className="text-white text-center mt-30" >We are growing big each passing day!</h4>

            <div
            className="mt-30 pb-30"
            >
                <div
                className="d-flex space-evenly flex-wrap gap-10"
                >
                    <div 
                    className="text-center width-flex"   
                    >
                        <h4 className="text-white fs-large">2</h4>
                        <p className="text-white f-uppercase" >Cities</p>

                    </div>

                    <div 
                    className="text-center width-flex"   
                    >
                        <h4 className="text-white fs-large">1000</h4>
                        <p className="text-white f-uppercase" >Driver partners</p>

                    </div>

                    <div 
                    className="text-center width-flex"   
                    >
                        <h4 className="text-white fs-large">10,000+</h4>
                        <p className="text-white f-uppercase" >Happy customers</p>

                    </div>

                    <div 
                    className="text-center width-flex"   
                    >
                        <h4 className="text-white fs-large">100,000+</h4>
                        <p className="text-white f-uppercase" >Trips so far</p>

                    </div>


                </div>

            </div>


        </div>
    );

}