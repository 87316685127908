import './App.css';
import NavBar from './components/nav/NavBar';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/home/HomePage';

function App() {
  return (
   <div
   >
   <NavBar />
   <div
   className='page-body'
   >
     <Routes>
      <Route
      path='/' element={
      <HomePage />
      }
      >

      </Route>
    </Routes>
   </div>
    
   </div>
  );
}

export default App;
