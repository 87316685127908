
export default function AppStoreDownload(){
    return(
       <div>
           <img
           className="google-play"
           src={require("../../images/appstore.png")}
           >
           </img>
       </div>
    );
}