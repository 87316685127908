import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class HomeCarousel extends Component {
    render() {
        return (
            <Carousel
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={3000}
            dynamicHeight={true}
            stopOnHover={false}
>
                <div>
                    <img 
                    className='carousel-image'
                    src={require("../../images/1.jpeg")} />
                  
                </div>

                <div>
                    <img 
                    className='carousel-image'
                    src={require("../../images/2.jpeg")} />
                  
                </div>

                <div>
                    <img 
                    className='carousel-image'
                    src={require("../../images/3.jpeg")} />
                  
                </div>

                <div>
                    <img 
                    className='carousel-image'
                    src={require("../../images/4.jpeg")} />
                  
                </div>

                <div>
                    <img 
                    className='carousel-image'
                    src={require("../../images/5.jpeg")} />
                  
                </div>
        
                <div>
                    <img 
                    className='carousel-image'
                    src={require("../../images/6.jpeg")} />
                  
                </div>
        
                <div>
                    <img 
                    className='carousel-image'
                    src={require("../../images/7.jpeg")} />
                  
                </div>
        
                <div>
                    <img 
                    className='carousel-image'
                    src={require("../../images/8.jpeg")} />
                  
                </div>
        
                <div>
                    <img 
                    className='carousel-image'
                    src={require("../../images/9.jpeg")} />
                  
                </div>
        
            </Carousel>
        );
    }
};

export default HomeCarousel;