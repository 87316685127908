import "./HomePage.css";
import HomeCarousel from "./Carousel"; 
import GooglePlayDownload from "../../components/downloadbuttons/GooglePlayDownload";
import AppStoreDownload from "../../components/downloadbuttons/AppStore";
import WhatWeOffer from "./WhatWeOffer";
import CallSupport from "../../components/downloadbuttons/CallSupport";
import WhatsappSupport from "../../components/downloadbuttons/WhatsappSupport";
import Vehicles from "./Vehicles";
import TrackRecord from "./TrackRecord";

export default function HomePage(){
    return (

            <div>
               
            <div
            className="row align-center space-evenly bg-home"
            >

                <div
                className="col-lg-3"
                >
            <HomeCarousel />
                
                </div>

                <div
                className="col-lg-6"
                >
                    <div className="page-container">

                    <div className="d-flex gap-30">

<h4 className="step-number">1</h4>

<div className="step-items mt-30">
    <h4>Pickup and drop anywhere within the city</h4>
    <p>Choose your pickup & drop location from within the city</p>
    
    <h6 className="mt-40 mb-10">Download the Mizigo app on</h6>
    <div className="d-flex gap-10" style={{marginBottom:'10px'}}>
        <GooglePlayDownload />
        <AppStoreDownload />

    </div>

</div>

</div>

<div className="underline"></div>

<div className="d-flex gap-30">

<h4 className="step-number">2</h4>

<div className="step-items mt-30 mb-10">
    <h4>Choose vehicle of your choice</h4>
    <p>Get quotes for vehicles which can carry from 20 kgs to 2000 kgs and book instantly without any waiting</p>
</div>

</div>  

<div className="underline"></div>

<div className="d-flex gap-30">

<h4 className="step-number">3</h4>

<div className="step-items mt-30 mb-10">
<h4>Real time tracking</h4>
<p>Our verified partner will ensure that your goods are transported safely to the destination. You can monitor the trip with live vehicle tracking and regular sms/email updates from our MOBILE APP</p>
</div>

</div>

                    </div>

                    

                </div>
            </div>

            <WhatWeOffer />
            <Vehicles />
            
            <div className="mt-30">
                <h4 className="heading text-center mb-10" >Know More</h4>
            <div
            className="center"
            >
                <div
                className="video-container"
                >

            <iframe frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen" 
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen"
            title="Mizigo - Your Logistics Partner" 
            webkitallowfullscreen="webkitallowfullscreen"
             src="https://www.youtube.com/embed/MSzm92qJs60"
            >
            </iframe>
            </div>

            </div>
            </div>

            <div>
            <div
            className="center"
            >
                <div
                className="video-container"
                >

            <iframe frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen="allowfullscreen"
            title="Just Mizigo it!"
            mozallowfullscreen="mozallowfullscreen" 
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            webkitallowfullscreen="webkitallowfullscreen"
             src="https://www.youtube.com/embed/Mj34fewVgzo"
            >
            </iframe>
            </div>

            </div>
            </div>

            <TrackRecord />

            <CallSupport />
            <WhatsappSupport />
           </div>
    );
}