import "./fixedbutton.css";

export default function CallSupport(){

    return(
        <div className="fixed-circle mb-10 mr-10 bg-black">
             <a
           href="tel:0800750235"
           >
               <i className="fa fa-phone fixed-circle-icon"></i>
            </a>
        </div>
    );

}