
import "./fixedbutton.css";

export default function WhatsappSupport(){

    return(
        
        <div className="fixed-circle mb-70 mr-10 bg-primary">
            <a href="https://api.whatsapp.com/send?phone=746000666" target="_blank" >
            <i className="fa fa-whatsapp fixed-circle-icon"></i>

            </a>
            
        </div>
    );

}