

export default function GooglePlayDownload(){
    return(
       <div>
           <a
           href="https://play.google.com/store/apps/details?id=com.devwithcoffee.mizigo"
           target="_blank"
           >
             <img
           className="google-play"
           src={require("../../images/googleplay.png")}
           >
           </img>
           </a>
          
       </div>
    );
}